<template>
  <!-- Link de Pago Vista previa-->
  <div class="container">
    <div class="row mx-2">
      <div class="col-12 mt-3">
        <b-card body class="text-center card-shadow">
          <b-img
            fluid
            src="../../../assets/images/celcomLogo/logo.png"
            alt="Logo Celcom"
            style="height: 30px"
          />
        </b-card>
      </div>
    </div>

    <div v-if="!linkEliminado">
      <div v-if="validateDate">
        <div class="row mx-2" v-if="linkCargado">
          <!-- Nombre y avatar -->
          <div class="col-6">
            <div class="row">
              <div class="col-12">
                <b-card body class="text-center card-shadow">
                  <div class="row">
                    <!-- <div class="col-2">
                      <b-avatar
                        size="70px"
                        :src="imagen"
                        variant="light"
                        class="avatarClass"
                      />
                    </div> -->
                    <div class="col-10">
                      <h4 class="font-weight-bolder text-left">
                        {{ nombreProducto }}
                      </h4>
                      <p class="textPromocional">
                        <strong>{{ textoPromocional }}</strong>
                      </p>
                      <span id="descripcion">{{ descripcionProducto }}</span>
                    </div>
                  </div>
                </b-card>
              </div>
              <div class="col-12">
            <b-card body class="card-shadow">
             <h4 class="mb-1 mt-1 ml-1">Código de descuento</h4>
             <div class="row align-items-end">
               <div class="col-8 mx-1">
              <!--  <b-form-group
                label="Ingrese su código de descuento"
                label-for="codigo"
              > -->
                <b-form-input id="codigo" v-model="codigo" type="url" placeholder="Ingrese su código" />
            <!--   </b-form-group> -->
             </div>
             <div class="col">
               <b-button
                    id="button-validar"
                    variant="primary"
                    @click="validarDescuentoToast('danger', 'No disponible en SandBox','Error')"
                  >
                    Validar
                  </b-button>
             </div>
             </div>
             
             
            </b-card>
          </div>
              <div class="col-12">
                <div id="card-monto" class="card-shadow">
                  <h4 class="mb-3 mt-1">Resumen de la compra</h4>

                  <hr />
                  <div class="row mb-1">
                    <div class="col-6" v-if="this.dataLinkActivo.type == 2">
                      <h6 class="mt-1">Período de cobro</h6>
                    </div>
                    <div class="col-6" v-if="this.dataLinkActivo.type == 2">
                      <h6 class="mt-1 text-right">{{ this.textPeriodicidad }}</h6>
                    </div>
                  </div>

                  <div class="row mb-1">
                    <div class="col-6">
                      <h6 class="mt-1">Subtotal</h6>
                    </div>
                    <div class="col-6">
                      <h6 class="mt-1 text-right">
                        {{ total }} {{ this.textMoneda }}
                      </h6>
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-6">
                      <h4 class="mt-1">Total</h4>
                    </div>
                    <div class="col-6 text-right" v-if="this.dataLinkActivo.type == 2">
                      <h5 class="mt-1 text-right">
                        <b class="text-right"
                          >{{ total }} {{ this.textMoneda }} /
                          {{ this.textPeriodicidad }}</b
                        >
                      </h5>
                    </div>
                    <div
                      class="col-6 text-right"
                      v-if="this.dataLinkActivo.type == 1"
                    >
                      <h5 class="mt-1 text-right">{{ total }} {{ this.textMoneda }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Campos formulario -->
          <div class="col-6">
            <b-card body class="card-shadow">
              <h4 class="mb-2 mt-1">Datos</h4>
              <span class="size-span-selecciona-medio">
                Todos los campos son obligatorios
              </span>
              <div>
                <div class="row">
                  <div
                    v-for="(listItem, index) in list2"
                    :class="listItem.column_size"
                    :key="index"
                  >
                    <label>{{ listItem.name ? listItem.name : '' }}</label>
                    <!-- v-id="index + '-Link'" -->
                    <b-form-input
                      class="mb-1"
                      v-model="listItem.model"
                      :state="statesDynamicForm[index]"
                    />
                  </div>
                </div>
              </div>

              <hr />

              <h4 class="mt-1 mb-0">Pago</h4>
              <span class="size-span-selecciona-medio"
                >Selecciona una forma de pago</span
              >
              <b-form-group>
                <v-select
                  v-model="formaElegida"
                  :dir="dir"
                  label="text"
                  :options="formaSelector"
                  :reduce="(Item) => Item.value"
                  class="select-size-lg mt-1"
                  @input="pasarelasSel()"
                  v-on:input="hideInputFormaPago()"
                  v-on:close="showInputFormaPago()"
                />
                <label
                  v-if="formaCheck"
                  class="font-italic"
                  style="color: red !important"
                  >Debe seleccionar una Forma de pago</label
                >
              </b-form-group>
              <hr class="mt-2" />
              <span class="size-span-selecciona-medio"
                >Selecciona una pasarela de pago</span
              >
              <b-form-group>
                <!-- <v-select v-model="medioElegido" :options="pasarelasSelector" :reduce="item => item.value" label="text" /> -->
                <v-select
                  v-model="medioElegido"
                  :dir="dir"
                  label="text"
                  :reduce="(Item) => Item.value"
                  :options="pasarelasSelector"
                  class="select-size-lg mt-1"
                  v-on:input="hideInputFormaPago()"
                  v-on:close="showInputFormaPago()"
                />
                <label
                  v-if="pasarelaCheck"
                  class="font-italic"
                  style="color: red !important"
                  >Debe seleccionar una Pasarela de pago</label
                >
                <!-- <v-select
                v-model="medioElegido"
                :dir="dir"
                :value="value"
                label="text"
                :options="pasarelasSelector"
                class="select-size-lg mt-1"
                v-on:input="hideInputMedioPago()"
                v-on:close="showInputMedioPago()"
              /> -->
                <!-- label="title" -->
              </b-form-group>

              <hr class="mt-2" />

              <div>
                <b-alert
                  v-height-fade.appear
                  :show="dismissCountDown"
                  dismissible
                  class="mb-1"
                  variant="warning"
                  id="empty-field-popup"
                  @dismissed="dismissCountDown = 0"
                  @dismiss-count-down="countDownChanged"
                >
                  <div class="alert-body">
                    <span class="bold">Advertencia! </span>
                    <br />
                    <span>Debes completar los campos obligatorios </span>
                  </div>
                </b-alert>
              </div>

              <!-- <div v-if="inputsMedioPagoVisible && formaElegida != 3"> -->
              <!-- <div
                v-if="
                  aceptoTerminosCondiciones &&
                    formaElegida != 3 &&
                    formaElegida != 4
                "
              > -->
                <h5 class="mt-2" v-if="medioElegido == 1 && formaElegida != 2">
                  {{ medioElegido == 1 ? 'Kushki' : 'Stripe' }}
                </h5>
                <h5 class="mt-2" v-if="medioElegido == 2 && formaElegida != 2">
                  {{ medioElegido == 2 ? 'Stripe' : 'Kushki' }}
                </h5>
                <div>
                  <div
                    class="row"
                    v-if="formaElegida == 1 || formaElegida == 2"
                  >
                    <div class="col-12">
                      <label>Email</label>
                      <b-form-input
                        class="mb-1 control"
                        id="card_email"
                        placeholder="Email"
                        type="email"
                        @input="cambia()"
                        required
                        v-model="emailCreditCard"
                      />
                    </div>

                    <div class="col-12">
                      <label>Nombre en la tarjeta</label>
                      <b-form-input
                        class="mb-1"
                        v-model="nombreTitular"
                        @input="cambia()"
                        id="input-nombreTitular"
                      />
                    </div>
                    <div class="col-12">
                      <label>Numero de Tarjeta</label>
                      <input
                        class="mb-1 form-control"
                        id="card-number"
                        v-model="numeroTarjeta"
                        @input="cambia()"
                        type="number"
                        v-mask="'################'"
                      />
                    </div>

                    <div class="col-6">
                      <label>Fecha exp</label>
                      <input
                        placeholder="mm/YY"
                        class="mb-1 form-control"
                        @input="cambia()"
                        id="card-expiry"
                        v-model="fechaExpiracion"
                        type="tel"
                        v-mask="'##/##'"
                      />
                    </div>
                    <div class="col-6">
                      <label>CVC</label>
                      <input
                        class="mb-1 form-control"
                        id="card-cvc"
                        v-model="cvv"
                        @input="cambia()"
                        type="password"
                        v-mask="'####'"
                      />
                    </div>
                  </div>
                  <!-- <div v-if="medioElegido == 1 && formaElegida != 2">
                    <div class="row">
                      <div class="col-12">
                        <label>Nombre en la tarjeta</label>
                        <b-form-input
                          class="mb-1"
                          v-model="nombreTitular"
                          id="input-nombreTitular"
                          @input="cambia()"
                          type="text"
                        />
                      </div>
                      <div class="col-12">
                        <label>Número de Tarjeta</label>
                        <b-form-input
                          class="mb-1 control"
                          id="card_number"
                          type="number"
                          @input="cambia()"
                          v-mask="'################'"
                          v-model="numeroTarjeta"
                        />
                      </div>
                      <div class="col-6">
                        <label>Fecha de expiración</label>
                        <b-form-input
                          placeholder="mm/YY"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="fechaExpiracion"
                          v-mask="'##/##'"
                        />
                      </div>
                      <div class="col-6">
                        <label>CVV</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          type="password"
                          @input="cambia()"
                          v-model="cvv"
                          v-mask="'####'"
                        />
                      </div>
                    </div>

                    <div class=" mt-1" v-if="formaElegida == 1">
                      <h5 class="mt-2">Datos de Facturación</h5>
                    </div>

                    <div class="row" v-if="formaElegida == 1">
                      <div class="col-12">
                        <label>Email</label>
                        <b-form-input
                          class="mb-1 control"
                          id="card_email"
                          placeholder="Email"
                          type="email"
                          @input="cambia()"
                          required
                          v-model="emailCreditCard"
                        />
                      </div>

                      <div class="col-12">
                        <label>Nombre</label>
                        <b-form-input
                          placeholder="Nombre"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="nombreCreditCard"
                        />
                      </div>
                      <div class="col-12">
                        <label>Apellido</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Apellido"
                          type="text"
                          @input="cambia()"
                          v-model="apellidoCreditCard"
                        />
                      </div>

                      <div class="col-12">
                        <label>Telefono</label>
                        <b-form-input
                          placeholder="Telefono"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="number"
                          min="0"
                          max="9"
                          v-model="telefonoCreditCard"
                        />
                      </div>
                      <div class="col-12">
                        <label>Direccion</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Direccion"
                          type="text"
                          @input="cambia()"
                          v-model="direccionCreditCard"
                        />
                      </div>

                      <div class="col-6">
                        <label>Ciudad</label>
                        <b-form-input
                          placeholder="Ciudad"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="ciudadCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <label>Pais</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          placeholder="Pais"
                          type="text"
                          @input="cambia()"
                          v-model="paisCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <label>Region</label>
                        <b-form-input
                          class="mb-1 control"
                          placeholder="Region"
                          id="cc-csc"
                          type="text"
                          @input="cambia()"
                          v-model="regionCreditCard"
                        />
                      </div>

                      <div class="col-6">
                        <label>Cod. Postal</label>
                        <b-form-input
                          placeholder="Cod Postal"
                          class="mb-1 control"
                          id="cc-exp"
                          @input="cambia()"
                          type="text"
                          v-model="codigoPostalCreditCard"
                        />
                      </div>
                      <div class="col-6">
                        <b-form-group>
                          <label>Tipo de Documento</label>
                          <v-select
                            v-model="tipoDocumentoCard"
                            :dir="dir"
                            label="text"
                            :options="documentOptions"
                            :reduce="(Item) => Item.value"
                            @input="elegirDocumento(tipoDocumentoCard)"
                            v-on:input="hideInputFormaPago()"
                            v-on:close="showInputFormaPago()"
                          />
                        </b-form-group>
                      </div>
                      <div class="col-6">
                        <label>N° de Doc.</label>
                        <b-form-input
                          class="mb-1 control"
                          id="cc-csc"
                          type="number"
                          min="0"
                          max="9"
                          @input="cambia()"
                          placeholder="Numero de doc."
                          v-model="documentoCard"
                        />
                      </div>
                    </div>
                  </div> -->
                </div>
              <!-- </div> -->

              <!-- <div v-if="formaElegida == 4 && medioElegido == 1">
                <h5 class="mt-2">Datos para el pago</h5>
              </div>
              <div v-if="formaElegida == 4 && medioElegido == 1">
                <div class="row">
                  <div class="col-12">
                    <label>Email</label>
                    <b-form-input
                      class="mb-1"
                      type="email"
                      required
                      v-model="emailTransferenciaKushki"
                      @input="cambia()"
                      id="input-nombreTitular"
                    />
                    <b-form-group>
                      <label>Tipo de Documento</label>
                      <v-select
                        v-model="documentTypeTransfer"
                        :dir="dir"
                        label="text"
                        :options="documentOptions"
                        :reduce="(Item) => Item.value"
                        class="mb-1"
                        @input="elegirDocumento(documentTypeTransfer)"
                        v-on:input="hideInputFormaPago()"
                        v-on:close="showInputFormaPago()"
                      />
                    </b-form-group>
                    <label>N° de Doc.</label>
                    <b-form-input
                      class="mb-1"
                      type="tel"
                      required
                      v-model="documentoTransfer"
                      @input="cambia()"
                      id="document-number"
                    />
                  </div>
                </div>
              </div> -->

              <!-- <div v-if="formaElegida == 3 && medioElegido == 1">
                <h5 class="mt-2">Datos para el pago</h5>
              </div> -->
              <!-- <div v-if="formaElegida == 3 && medioElegido == 1">
                <div class="row">
                  <div class="col-6">
                    <label>Nombre</label>
                    <b-form-input
                      class="mb-1"
                      v-model="nombreEfectivoKushki"
                      @input="cambia()"
                      type="text"
                      id="input-nombreTitular"
                    />
                  </div>
                  <div class="col-6">
                    <label>Apellido</label>
                    <b-form-input
                      class="mb-1"
                      v-model="apellidoEfectivoKushki"
                      @input="cambia()"
                      type="text"
                      id="input-nombreTitular"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>N° de identificacion</label>
                    <b-form-input
                      class="mb-1"
                      v-model="identificacionEfectivoKushki"
                      @input="cambia()"
                      type="number"
                      id="input-nombreTitular"
                    />
                  </div>
                  <div class="col-6">
                    <label>Email</label>
                    <b-form-input
                      class="mb-1"
                      required
                      v-model="emailEfectivoKushki"
                      @input="cambia()"
                      type="email"
                      id="input-nombreTitular"
                    />
                  </div>
                </div>
              </div> -->

              <div class="row align-items-center termsAndconds">
                <div>
                  <b-form-checkbox
                    v-model="aceptoTerminosCondiciones"
                    class="custom-control-info"
                  >
                    <label class="label-terms"
                      >Acepto los Términos y Condiciones</label
                    >
                  </b-form-checkbox>
                </div>
                <div>
                  <b-button
                    variant="link"
                    class="p-0 ml-1 mt-0 ver-mas-button"
                    @click="modalTermsShow = !modalTermsShow"
                    v-b-modal.modal-terms
                    >Ver mas</b-button
                  >
                </div>
                <label
                  v-if="terminos"
                  class="font-italic"
                  style="color: red !important"
                  >Debe aceptar los Terminos y Condiciones</label
                >
              </div>

              <div class="row align-items-center">
                <div class="col justify-content-end">
                  <div style="display: flex">
                    <div style="margin-left: auto">
                      <b-button
                        id="button-pagar"
                        variant="primary"
                        @click="validateEmptyFields()"
                        :disabled="activatePayButton(formaElegida)"
                      >
                        Pagar {{ this.textMoneda }} {{ this.total }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="!linkVencido">
          <div class="col-12 text-center">
            <b-card body class="text-center card-shadow">
              <h4 class="font-weight-bolder">
                El link de pago todavía no esta activo.
              </h4>
              <p>Se habilitará el dia {{ fechaValidacion }}</p>
            </b-card>
          </div>
        </div>
        <div v-else>
          <div class="col-12 text-center">
            <b-card body class="text-center card-shadow">
              <h4 class="font-weight-bolder">
                El link de pago ha expirado.
              </h4>
              <p>Se deshabilitó el dia {{ fechaValidacion }}</p>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="col-12 text-center">
        <b-card body class="text-center card-shadow">
          <h4 class="font-weight-bolder">
            El link de pago no existe.
          </h4>
        </b-card>
      </div>
    </div>

    <b-modal
      id="modal-terms"
      v-model="modalTermsShow"
      hide-header-close
      header-class="py-2"
      title="Términos y Condiciones"
      ok-only
      ok-title="Cerrar"
      centered
      size="xl"
      ref="modal-terms"
      scrollable
    >
      <div class="p-2">
        <span>
          {{ terminosCondiciones }}
        </span>
      </div>
    </b-modal>
  </div>
</template>

<script>

/* eslint-disable global-require */
import {
  BLink,
  BFormInput,
  BButton,
  BForm,
  BImg,
  BCard,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAlert,
  BFormCheckbox,
} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store/index';
import draggable from 'vuedraggable';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import { mask } from 'vue-the-mask';
import {
  backendUrl,
  frontEndUrl,
  TOKEN_TRANSFER_KUSHKI,
  TOKEN_CASH_KUSHKI,
  TOKEN_DEBIT_KUSHKI,
} from '@/config';
import axios from 'axios';
import { Kushki } from '@kushki/js';
import { urlKushki } from '../../../config/index';
import { requestKushkiTokenCredit } from './kushki/index';
import validation from '../../../helpers/validate-fields';

import {
  siteUrl,
  ssl,
  enableLogs,
  celcomKushkiMerchantId,
  isProduction,
} from '../../../config/index';

export default {
  components: {
    BLink,
    BFormInput,
    BButton,
    BForm,
    BImg,
    BCard,
    BAvatar,
    VuexyLogo,
    draggable,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ToastificationContent,
    BAlert,
    vSelect,
    mask,
    BFormCheckbox,
  },

  data() {
    return {
      hidePostalCode: true,
      token: null,
      charge: null,
      dataLinkActivo: {},
      fieldLinkActivo: [],
      dismissSecs: 5,
      dismissCountDown: 0,
      downImg: require('@/assets/images/pages/under-maintenance.svg'),
      logoProducto: '@/assets/images/celcomLogo/logo.png',
      nombreProducto: '',
      descripcionProducto: '',
      list2: [],
      textoPromocional: '',
      nombreCliente: '',
      total: 0,
      moneda: {},
      documentoTransfer: '',
      documentTypeTransfer: '',
      textMoneda: '',
      textMonedaArray: [],
      modalTermsShow: false,
      nombreTitular: '',
      numeroTarjeta: '',
      fechaExpiracion: null,
      fechaLanzamiento: null,
      linkVencido: false,
      linkEliminado: false,
      mesExp: '',
      anioExp: '',
      cvv: '',
      emailTitular: '',
      cardComplete: false,
      inputsDynamicFormCompleted: false,
      dir: 'ltr',
      medioElegido: '',
      inputsMedioPagoVisible: true,
      formasPago: [],
      formaElegida: '',
      inputsFormaPagoVisible: false,
      modalTermsShow: false,
      mediosPago: [],
      metodoPago: [],
      pasarelasSelector: [],
      mediosPago2: [],
      periodicidad: '',
      terminos: null,
      formaCheck: null,
      linkCargado: false,
      validateDate: true,
      fechaVencimiento: null,
      fechaValidacion: '',
      pasarelaCheck: null,
      statesDynamicForm: [],
      textPeriodicidad: '',
      impuestos: 0,
      diasPrueba: '',
      clientSecret: '',
      cardObject: '',
      aceptoTerminosCondiciones: '',
      terminosCondiciones: '',
      nombreEfectivoKushki: '',
      apellidoEfectivoKushki: '',
      identificacionEfectivoKushki: '',
      emailEfectivoKushki: '',
      emailTransferenciaKushki: '',
      nombreCreditCard: '',
      apellidoCreditCard: '',
      telefonoCreditCard: '',
      emailCreditCard: '',
      direccionCreditCard: '',
      paisCreditCard: '',
      ciudadCreditCard: '',
      codigoPostalCreditCard: '',
      regionCreditCard: '',
      tipoDocumentoCard: '',
      documentoCard: '',
      emailDebitCard: '',
    };
  },
  setup() {
    const optionMetodoPago = [
      { title: 'Tajeta de Credito', value: 1 },
      { title: 'Tajeta de Debito', value: 2 },
      { title: 'Efectivo', value: 3 },
      { title: 'Transferencia', value: 4 },
    ];
    const documentOptions = [
      { text: 'RUT', value: 'RUT' },
      { text: 'CC', value: 'CC' },
      { text: 'LIC. DE CONDUCIR', value: 'LIC. DE CONDUCIR' },
    ];
    const optionMediosPago = [
      { text: 'Tarjeta de Crédito', value: 1, pasarela: [1, 2] },
      { text: 'Tarjeta de Débito', value: 2, pasarela: [1] },
      { text: 'Efectivo', value: 3, pasarela: [1] },
      { text: 'Transferencia', value: 4, pasarela: [1] },
      { text: 'Entel', value: 5, pasarela: [3] },
      { text: 'Movistar', value: 6, pasarela: [3] },
    ];
    const optionPasarelas = [
      { text: 'Kushki', value: 1 },
      { text: 'Stripe', value: 2 },
      { text: 'Suscripciones Móviles', value: 3 },
    ];
    return {
      optionMediosPago,
      optionMetodoPago,
      optionPasarelas,
      documentOptions,
    };
  },
  directives: { mask },

  mounted() {
    this.getLinkDePago();
  },
  methods: {
    elegirDocumento(tipoDoc) {
    },
    validateFieldsCashKushki() {
      return (
        this.formaElegida == 1 &&
        this.nombreCreditCard !== '' &&
        this.apellidoCreditCard !== '' &&
        this.documentoCard !== '' &&
        this.tipoDocumentoCard !== '' &&
        this.telefonoCreditCard !== '' &&
        this.direccionCreditCard !== '' &&
        this.paisCreditCard !== '' &&
        this.ciudadCreditCard !== '' &&
        this.regionCreditCard !== '' &&
        this.codigoPostalCreditCard !== '' &&
        this.tipoDocumentoCard !== '' &&
        this.emailCreditCard !== ''
      );
    },
    cambia() {
      if (
        this.nombreTitular != '' &&
        this.numeroTarjeta != '' &&
        this.cvv != '' &&
        this.cvv.length > 2 &&
        this.fechaExpiracion != ''
      ) {
        this.cardComplete = true;
      } else {
        this.cardComplete = false;
      }
    },
   

    showInputMedioPago() {
      this.inputsMedioPagoVisible = true;
    },
    hideInputMedioPago() {
      this.inputsMedioPagoVisible = false;
    },
    showInputFormaPago() {
      this.inputsFormaPagoVisible = true;
    },
    hideInputFormaPago() {
      this.inputsFormaPagoVisible = false;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showPagoExitosoToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Pago exitoso!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },
    cambiarMedio() {
    
    },

    showDateLinkInactive(variant, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'No es posible acceder al link',
          text: `Intente de nuevo el dia ${date}`,
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showPagoErrorToast(variant, mensaje) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${mensaje}`,
          text: 'Intente con otra tarjeta',
          icon: 'CrossIcon',
          variant,
        },
      });
    },
    showErrorCreditCard(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          icon: 'XIcon',
          variant,
        },
      });
    },
    showTarjetaInvalida(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ocurrio un error con la tarjeta.',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showErrorTransfer(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ocurrio un error cuando se realizo la transferencia.',
          text: 'Intente nuevamente más',
          icon: 'XIcon',
          variant,
        },
      });
    },

    showErrorCreditCard(variant, msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `${msg}`,
          icon: 'XIcon',
          variant,
        },
      });
    },

    async getLinkDePago() {
      this.$store.commit('appConfig/changeLoading', true);

      // const token = localStorage.getItem('token');
      /************************************* Modificar la Entrada de Datos**********************************************************************/
      var config = {
        method: 'post',
        url: `${backendUrl}/link-pago/linkPreviaDev`,
        data: {
          id: this.$router.history.current.params.id,
          pass: this.$router.history.current.query.pass,
        },
      };

      try {
        const resp = await axios(config);
        let hoy = new Date();
        let desde = new Date(resp.data.link[0].date_from);
        let hasta =
          resp.data.link[0].date_to == null
            ? null
            : new Date(resp.data.link[0].date_to);

        if (desde > hoy) {
          this.fechaLanzamiento = desde;

          this.fechaValidacion =
            new Date(this.fechaLanzamiento).getDate() +
            '/' +
            (new Date(Date.now(this.fechaLanzamiento)).getMonth() + 1) +
            '/' +
            new Date(this.fechaLanzamiento).getFullYear();

          this.validateDate = false;
          this.linkVencido = false;
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }
        if (hasta != null && hasta <= hoy) {
          this.fechaVencimiento = hasta;
          this.fechaValidacion =
            new Date(this.fechaVencimiento).getDate() +
            '/' +
            (new Date(Date.now(this.fechaVencimiento)).getMonth() + 1) +
            '/' +
            new Date(this.fechaVencimiento).getFullYear();
          this.validateDate = false;
          this.linkVencido = true;
          this.$store.commit('appConfig/changeLoading', false);

          return;
        }
        let linkActivo = await resp.data.link.filter(
          (e) => e.id == this.$route.params.id
        );

        this.linkData = linkActivo[0];

        this.dataLinkActivo = linkActivo[0];

        this.dataLinkActivo.pasarela = JSON.parse(this.dataLinkActivo.pasarela);
        this.dataLinkActivo.metodoPago = JSON.parse(
          this.dataLinkActivo.metodoPago
        );

        for (let k = 0; k < linkActivo[0].field.length; k++) {
          linkActivo[0].field[k].model = '';
        }
        this.list2 = linkActivo[0].field;
        for (let v = 0; v < this.list2.length; v++) {
          this.nombreCliente = this.list2[v].model;
        }
        this.nombreProducto = linkActivo[0].name;
        this.descripcionProducto = linkActivo[0].description;
        this.textoPromocional = linkActivo[0].textPromo;
        this.total = linkActivo[0].amount;
        this.moneda = linkActivo[0].currency;
        this.periodicidad = linkActivo[0].periodicidad;
        this.textPeriodicidad =
          this.periodicidad == 1
            ? 'Dia'
            : 2
            ? 'Semanal'
            : 3
            ? 'Quincenal'
            : 4
            ? 'Mes'
            : 5
            ? 'Trimestre'
            : 6
            ? 'Semestre'
            : 7
            ? 'Año'
            : null;
        this.terminosCondiciones = linkActivo[0].Terms_and_Conditions;
        this.metodoPago = this.dataLinkActivo.metodoPago;
        this.imagen = `${backendUrl}/link-pago/getImg/${linkActivo[0].url_imgage.replace(
          '/warehouse/',
          ''
        )}`;
        this.mediosPago = this.dataLinkActivo.pasarela; /* this.mediosPagoGen.map(function(x) {
          return x == 2 ? 'Stripe' : 1 ? 'Kushki' : null;
        }); */
        this.textMonedaArray = [
          {
            id: 1,
            text: 'USD',
          },
          {
            id: 2,
            text: 'ARS',
          },
          { id: 3, text: 'CLP' },
        ];
        for (let m = 0; m < this.textMonedaArray.length; m++) {
          if (this.textMonedaArray[m].id == this.moneda) {
            this.textMoneda = this.textMonedaArray[m].text;
          }
        }
       
        for (let i = 0; i < this.list2.length; i++) {
          this.statesDynamicForm.push(null);
        }

        this.$store.commit('appConfig/changeLoading', false);

        this.linkCargado = true;
      } catch (error) {
        this.linkEliminado = true;
        this.validateDate = false;
        this.$store.commit('appConfig/changeLoading', false);
        this.errorLinkToast('danger');

 
      }
    },
    checkDynamicForm(name, model, index) {
      const Faltantes = [
        'Dirección',
        'Nombre de Empresa',
        'RUT de Empresa',
        'Giro de Empresa',
        'Dirección de Empresa',
      ];
      //this.statesDynamicForm[index]=false;
      if (model.length > 3) {
        if (name === 'Nombre' && !validation.isText(model)) {
          return true;
        } else if (name === 'Apellido' && !validation.isText(model)) {
          return true;
        } else if (name === 'Email' && !validation.isEmail(model)) {
          return true;
        } else if (name === 'Teléfono' && !validation.isPhone(model)) {
          return true;
        } else if (name === 'RUT' && !validation.isRut(model)) {
          return true;
        } else if (name === 'Email de Empresa' && !validation.isEmail(model)) {
          return true;
        } else if (name === 'RUT de Empresa' && !validation.isRut(model)) {
          return true;
        }
        //this.statesDynamicForm[index]=true;
        return false;
      } else {
        return true;
      }
    },
    EmptyFields() {
      for (let i = 0; i < this.list2.length; i++) {
        this.statesDynamicForm[i] = false;
        this.statesDynamicForm[i] = !this.checkDynamicForm(
          this.list2[i].name,
          this.list2[i].model,
          i
        );
      }
      this.statesDynamicForm = [...this.statesDynamicForm];
      this.terminos = !this.aceptoTerminosCondiciones;
      this.medioElegido != null && this.medioElegido != ''
        ? (this.pasarelaCheck = false)
        : (this.pasarelaCheck = true);
      this.formaElegida != null && this.formaElegida != ''
        ? (this.formaCheck = false)
        : (this.formaCheck = true);
      return (
        this.statesDynamicForm.includes(false) ||
        this.pasarelaCheck ||
        this.terminos ||
        this.formaCheck
      );
    },

    async validateEmptyFields() {
      this.$store.commit('appConfig/changeLoading', true);
     
      if (this.EmptyFields()) {
        this.$store.commit('appConfig/changeLoading', false);
        this.showErrorFields('danger');

        return;
      }
      if (this.formaElegida == 1||this.formaElegida == 2) {
        //const redirectUrl = await this.debitKushki();
        var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!regex.test(this.emailCreditCard)) {
          this.showErrorMailToast('danger');
          this.$store.commit('appConfig/changeLoading', false);
          return;
        }
        if (this.cardComplete) {
          this.$store.commit('appConfig/changeLoading', true);
        } else {
          this.showErrorToast('danger');
          this.$store.commit('appConfig/changeLoading', false);

          return;
        }
        for (let g = 0; g < this.list2.length; g++) {
          const element = {
            name: this.list2[g].name ? this.list2[g].name : '',
            value: this.list2[g].model,
          };
          this.fieldLinkActivo.push(element);
        }
        let card = {
          number: this.numeroTarjeta,
          cvc: this.cvv,
          exp_month: this.mesExp,
          exp_year: this.anioExp,
        };


      }
      await this.paySandbox(); 
      this.validarDescuentoToast('success', 'pago correcto', 'Pago realizado')
      this.$store.commit('appConfig/changeLoading', false);
      this.$router.push("/pages/miscellaneous/PaymentSuccessSandbox");
      return;

      
    },
    async paySandbox(){
      /* cambiar por el EP de Transacciones Fake */
      const data = {
        token:'ashdjashdagdhghabd',
        amount: this.total,
        id_project: this.dataLinkActivo.id_project,
        type: this.dataLinkActivo.type,
        date: new Date()
          .toJSON()
          .slice(0, 19)
          .replace('T', ' '),
        id_credential: this.medioElegido,
        id_state: 1,
        id_currency: parseInt(this.dataLinkActivo.currency),
        email_user_app: this.emailTitular!=''?this.emailTitular:'abc@abc.com',
        id_link: this.dataLinkActivo.id,
        token_pay: 'ashdjashdagdhghabd',
        used: 0,
        transaction_field: this.dataLinkActivo.field,
      };
      var config = {
        method: 'post',
        url: `${backendUrl}/services/pay/test`,
        data
      };
      if (this.dataLinkActivo.callback) {
        let varp =
          (this.dataLinkActivo.callback.indexOf('?') != 0 ? '&' : '?') +
          'idtr=' +
          this.dataLinkActivo.id;
        window.localStorage.setItem(
          'cb',
          this.dataLinkActivo.callback + varp
        );
      } else {
        window.localStorage.removeItem('cb');
      }
      try {
        const resp = await axios(config);
      }
      catch(err){}
      return new Promise(async resolve => {
        setTimeout(function(){
          resolve("OK"); // ¡Todo salió bien!
        }, 2000);
      });
    },


    redirect(red) {
      window.location.replace(red);
    },

   
    validarDescuentoToast(variant, message, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          text: message,
          icon: variant == 'success' ? 'CheckIcon' : 'XIcon',
          variant,
        },
      });
    },
    showErrorMailToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'El campo de mail no es correcto',
          icon: 'XIcon',
          variant,
        },
      });
    },
    showErrorToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: '¡Faltan datos a completar!',
          icon: 'CheckIcon',
          variant,
        },
      });
    },

    showErrorFields(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Error en los campos',
          text: 'Intente nuevamente',
          icon: 'XIcon',
          variant,
        },
      });
    },

    errorLinkToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Este link de pago no existe',
          icon: 'XIcon',
          variant,
        },
      });
    },

    pasarelasSel() {
      var me = this;
      this.medioElegido = '';
      var result = [];
      if (me.formaElegida != '') {
        result = me.optionPasarelas.filter((pasa) => {
          var bool = false;
          me.optionMediosPago.map((medios) => {
            if (
              this.mediosPago.includes(pasa.value) &&
              medios.pasarela.indexOf(pasa.value) > -1 &&
              me.formaElegida == medios.value
            ) {
              bool = true;
            }
          });
          if (bool) return pasa;
        });
      }
      this.pasarelasSelector = Object.assign([], result);

      //this.pasarelasSelector = result;
    },

    activatebuttonByMeansOfPayment(number) {
      return (
        this.medioElegido == 1 &&
        this.formaElegida == number &&
        this.aceptoTerminosCondiciones
      );
    },

    activatePayButton(formaSeleccionada) {
      const activate = {
        1: this.cardComplete,
        2: this.cardComplete,
        3: true,
        4: true,
      };

      return !(activate[formaSeleccionada] && this.aceptoTerminosCondiciones);
    },

    formatDate(dateToFormat) {
      const date = new Date(dateToFormat);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      return dt + '/' + month + '/' + year;
    },
  },
  computed: {
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.downImg = require('@/assets/images/pages/under-maintenance-dark.svg');
      //   return this.downImg;
      // }
      // return this.downImg;
    },
    formaSelector() {
      var me = this;
      var result = me.optionMediosPago.filter((medio) => {
        if (me.metodoPago.indexOf(medio.value) != -1) return medio;
      });
      return result;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.clearfix {
  clear: both;
  float: none;
}
.mb-1 form-control {
  padding: 20px;
  border: 1px solid #ccc;
}

#card-monto {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 8px;
  padding-top: 16px;
  margin-bottom: 30px;
}
.textPromocional {
  text-align: left;
}
.card-shadow {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

#descripcion {
  text-align: left;
  display: block;
  font-size: 13px;
  line-height: 21px;
}

#button-pagar {
  margin-top: 24px;
  width: 100%;
}

.bold {
  font-weight: bold;
}

#input-nombreTitular {
  text-transform: uppercase;
}

.size-span-selecciona-medio {
  font-size: 12px;
}

.termsAndconds {
  margin-left: 0%;
  margin-top: 8px;
}

.avatarClass img {
  object-fit: contain !important;
}

.ver-mas-button {
  margin-bottom: 4px;
  font-weight: bolder;
  color: $info;
}

.modal .modal-content {
  height: 540px;
}

.label-terms {
  font-weight: bolder;
  font-size: 14px;
}
//SPINNER
#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 100%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 40%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

#button-validar{
  width: 100%;
}


@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
